
.order-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;
    .btn-box {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .answer {
        display: flex;
        align-items: center;

        img {
            width: 80px;
            height: 80px;
        }

        .text {
            padding-left: 12px;
        }
    }

    .order-item {
        display: flex;
        align-items: center;

        ::v-deep .el-form-item__content {
            display: flex;
            margin-left: 24px !important;
        }

        ::v-deep .el-input-number {
            .el-input__inner {
                text-align: left;
            }
        }
    }

    .notes-title {
        color: #999;
        padding: 0 100px;
    }

    .dialog-footer {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
}
